@import url("https://fonts.googleapis.com/css2?family=Gamja+Flower&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tiny5&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jomhuria&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barriecito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rampart+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tilt+Warp&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kirang+Haerang&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bagel+Fat+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bagel+Fat+One&family=Belanosima:wght@400;600;700&family=Quicksand:wght@300..700&display=swap");

p {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.2em;
  margin-bottom: 20px;
  margin-top: 15px;
}

h1 {
  font-family: "Bagel Fat One", system-ui;
  letter-spacing: 0.01em;
  font-weight: 400;
  font-style: normal;
  font-size: 4em;
  color: black;
  margin: 0;
  padding: 0;
}

h2 {
  font-family: "Quicksand", sans-serif;
  letter-spacing: 0.01em;
  font-weight: 400;
  font-style: bold;
  font-size: 2em;
  color: black;
  margin-top: -50px;
  padding: 0;
}

h3 {
  font-family: "Quicksand", sans-serif;
  font-size: 2em;

  color: white;
  margin-bottom: -10px;
}

video {
  width: 100%;
  height: 100%;
}

/* OVERLAY UI ----------------------------*/

.OverlayContainer {
  position: fixed;
  width: 100vw;
  height: 100vh; /* Full viewport height */
  overflow-y: scroll;
  overflow-x: hidden;
  /*scroll-snap-type: y;*/
  display: flex;
  flex-direction: column;
}

.mySection {
  width: 70vw;
  scroll-snap-align: start;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  margin: auto;
}

@media (orientation: portrait) {
  .mySection {
    width: 99vw;
  }
}

/* COVER ----------------------------------*/

.CoverContainer {
  position: absolute;
  bottom: 10%;
  justify-self: flex-end;
}

.myTitle {
  font-family: "Bagel Fat One", system-ui;
  letter-spacing: 0.01em;
  font-weight: 400;
  font-style: normal;
  font-size: 15em;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  padding: 0;
}
.mySubTitle {
  text-align: right;
  padding-right: 5px;
}

@media (orientation: portrait) {
  .CoverContainer {
    width: 80vw;
  }
  .myTitle {
    text-align: center;
    font-size: 18vw;
    margin-bottom: 50px;
  }
  .mySubTitle {
    font-size: 7vw;
    text-align: center;
    line-height: 1.2em;
  }
}

/* SERVICES --------------------------------*/

.ServicesContainer {
  color: white;
  max-width: 1000px;
  display: flex;
  gap: 20px;
}

.ServicesTextWrap {
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 50px;
  text-align: center;
  flex: 1;
  padding: 40px;
  height: fit-content;
}
.ServicesTextWrap:nth-child(2) {
  margin-top: 50px;
}
.ServicesTextWrap p {
  color: rgba(255, 255, 255, 0.7);
}

.serviceTitle span {
  font-family: "Bagel Fat One", sans-serif;
  font-size: 2em;
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.85);
}

.serviceTitle {
  padding-bottom: 20px;
  padding-left: 20px;
  align-self: flex-start;
  justify-self: flex-end;
  color: rgba(0, 0, 0, 0.85);
}

.ServicesTextWrap h3 {
  font-family: "Bagel Fat One", sans-serif;
  letter-spacing: 0.03em;
  font-weight: 100;
}
@media (orientation: portrait) {
  .ServicesContainer {
    flex-direction: column;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 50px;
    margin: 10px;
    padding: 15px;
  }
  .serviceTitle {
    font-size: 1.5em;
    margin-left: 30px;
  }
  .ServicesTextWrap {
    background-color: transparent;
    padding: 0px;
    margin: 10px;
  }
  .ServicesTextWrap h3 {
    font-size: 1.3em;
    margin-top: 5px;
    margin-bottom: 1px;
  }
  .ServicesTextWrap:nth-child(2) {
    margin: 0;
  }
  p {
    font-size: 1em;
    line-height: 1.4em;
    margin: 10px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

/* SOCIAL AR EXAMPLES ..........................*/

.ar-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 equal-width columns */
  gap: 20px; /* Space between the columns and rows */
  width: 70%;
  max-width: 1000px;
  margin: auto;
}

/* Mobile-specific container */
.mobile-ar-grid {
  display: flex;
  padding-top: 20%;
  padding-bottom: 20%;
}

/* Scrollable container for AR cards */
.mobile-ar-card-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 5px;
  max-width: 100%;
  flex-wrap: nowrap;
}

/* AR card styling */
.mobile-ar-card-container {
  flex-shrink: 0; /* Prevent shrinking */
  width: auto; /* Ensure cards maintain their width */
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between ARCards */
}

.ar-card-container {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between ARCards within the column */
}

.ar-grid .ar-card-container:nth-child(2) {
  padding-top: 50px;
}

.ARCard {
  border-radius: 40px;
  aspect-ratio: 9 / 16;
  overflow: hidden;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4); /* Horizontal, vertical, blur, color */
}

.ARCard_mobile {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4); /* Horizontal, vertical, blur, color */
  border-radius: 40px;
  overflow: hidden;
  aspect-ratio: 9 / 16;
  min-width: 85vw;
  margin-left: 10px;
}

.videoPlayerVert {
  width: 100%;
  height: 100%;
}

/* CSS */
.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in.loaded {
  opacity: 1;
}

/* PREVIOUS WORK -------------------------------*/

.PrevWorkContainer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
}

.VideoContainer {
  width: 100%;
  margin: auto;
  width: 70%;
}

.prevWorkTitle {
  font-family: "Bagel Fat One", system-ui;
  letter-spacing: 0.02em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}
iframe {
  margin: 0;
  padding: 0;
  width: 100%;
  aspect-ratio: 16/9;
  border: none;
}
@media (orientation: portrait) {
  .VideoContainer {
    width: 100vw;
  }
  .prevWorkTitle {
    text-align: center;
  }
}

/* ABOUT US ------------------------------*/

.AboutUsContainer {
  height: 100%;
  width: 80vw;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutUsTitle {
  color: rgba(0, 0, 0, 0.85);
  font-family: "Bagel Fat One", system-ui;
  letter-spacing: 0.02em;
  font-weight: 400;
  font-size: 5em;
  text-align: center;
}

.Team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TeamMember {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.TeamMember:nth-child(1) {
  position: relative;
  left: -110px;
}
.TeamMember:nth-child(2) {
  position: relative;
  right: -0px;
}

.MemberText li,
.MemberText ul {
  color: rgba(255, 255, 255, 0.7);
}

.MemberText h3,
.MemberText a {
  margin-top: 3px;

  color: white;
}

.MemberText h3 {
  margin-top: 0px;
  margin-bottom: 3px;
  font-family: "Bagel Fat One", system-ui;
  letter-spacing: 0.02em;
  font-weight: 400;
}

.MemberText {
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 50px;
  padding: 20px 60px 20px 60px;
  margin: 20px;
}

.MemberText ul {
  margin: 0px;
  padding-left: 15px;
}

.JobDescription {
  margin: 0px;
  margin-bottom: 20px;
  color: white;
}

.MyImg {
  height: 300px;
  width: 300px;
  border-radius: 50%;
}
.MyImg:nth-child(1) {
  position: relative;
  right: -60px;
}
.MyImg:nth-child(2) {
  position: relative;
  left: -60px;
  right: 50px;
}

.socials {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.social_1 {
  height: 20px;
  width: 20px;
}

.mobileOnly {
  display: none;
}

@media (orientation: portrait) {
  .AboutUsContainer {
    width: 100%;
    height: 100%;
  }
  .TeamMember {
    flex-direction: column;
  }
  .TeamMember:nth-child(1) {
    left: 0px;
  }
  .TeamMember:nth-child(2) {
    display: none;
    /*hiding the container and adding another section instead - more space*/
  }
  .mobileOnly {
    display: flex;
  }
  .MemberText {
    padding: 80px 20px 30px 30px;
    margin: 10px;
  }
  .MyImg {
    width: 200px;
    height: 200px;
    margin-bottom: -80px;
  }
  .MyImg:nth-child(1) {
    position: relative;
    right: 0px;
  }
  .JobDescription {
    text-align: center;
  }
  ul {
    padding-left: 20px;
  }
  .socials {
    margin-top: 10px;
    flex-direction: row;
    gap: 20px;
  }
  .social_1 {
    width: 30px;
    height: 30px;
  }
  a {
    text-align: center;
  }
  h3 {
    text-align: center;
  }
}

/* CONTACT ---------------------------------*/

.ContactContainer {
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  padding: 30px;
  border-radius: 50px;
  width: 30%;
}

.contact {
  display: flex;
  gap: 10px;
}

.contact p {
  margin: 0px;
}

.contact h3 {
  margin: 0px;
  padding: 0px;
}
.ContactContainer a {
  color: white;
}
.ContactContainer .socials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 50%;
  margin: auto;
}

.ContactContainer .social_1 {
  filter: invert(1);
}

.hiddenImpressum {
  width: 100%;
}

@media (orientation: portrait) {
  .ContactContainer {
    width: 78%;
  }
  h1 {
    font-size: 3em;
  }
  h3 {
    font-size: 1.8em;
  }
  .hiddenImpressum {
    width: fit-content;
  }
}
